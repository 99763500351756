import { auth, signOut } from '@/firebase'
import getUserDocument from '@/util/cloudFunctions/userFunctions'

const DEFAULT_ERROR_MESSAGE =
    'Something went wrong. Please contact vibefam if the problem persists.'

export const actionsAuth = {
    authAction({ commit, state }) {
        auth.onAuthStateChanged(async (user) => {
            commit('setLoading', true)
            if (user) {
                const isRegistering = state.isRegistering
                try {
                    await setUserDocument(commit, user, isRegistering)
                } catch (error) {
                    commit('setError', DEFAULT_ERROR_MESSAGE)
                    commit('setLoading', false)
                }
            } else {
                commit('setUser', null)
            }
            commit('setLoading', false)
        })
    },
    setBrandedApp({ commit }) {
        commit('setBrandedApp', true)
    },
    setIsRegistering({ commit }, isRegistering) {
        commit('setIsRegistering', isRegistering)
    },
    closeCollectUserDetails({ commit }) {
        commit('setCollectUserDetails', false)
    },
    refreshUserDocument({ commit, getters }) {
        const user = getters.getUser
        setUserDocument(commit, user)
    },
}

async function setUserDocument(commit, user, isRegistering) {
    commit('setError', null)
    const result = await getUserDocument(user.uid || user.id)

    if (result.success) {
        const userDoc = result.doc
        commit('setUser', userDoc)
    } else {
        // Logged in but cannot find user document, so we need to collect the user details
        // Only happens with external auth
        if (auth.currentUser.providerData[0].providerId !== 'password') {
            commit('setUser', { id: auth.currentUser.uid })
            commit('setCollectUserDetails', true)

            return
        }

        // Handle errors during login
        // This might occur due to an unknown bug in the signup process.
        // In the signup process, the user may have been created in Firebase Auth,
        // but the user document in the Firestore collection was not created.
        // As a result, the login process should fail and display an error message.
        commit('setError', result.error || DEFAULT_ERROR_MESSAGE)

        /**
         * If the user is not registering, sign them out. This case is to handle the bug when user is logged in but the user document is not found.
         * And if the user is registering, don't sign them out as the user will be deleted from Firebase Auth.
         */
        if (!isRegistering) {
            await signOut(auth)
        }
    }
}
