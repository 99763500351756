import { functions, httpsCallable } from '@/firebase'

/**
 * Grabs the user document.
 * @param {String} userId
 */
function getUserDocument(userId) {
    const getUser = httpsCallable(functions, 'getUser')
    return getUser({ callerId: userId }).then((result) => result.data)
}

export function updateMobileNumber(mobileNumber) {
    const updateNumber = httpsCallable(functions, 'updateMobileNumber')
    return updateNumber({ mobileNumber }).then((result) => result.data)
}

export default getUserDocument
