<template>
    <div>
        <v-row class="d-flex flex-column mb-2">
            <v-row class="align-center">
                <v-col class="text-left"> Subtotal </v-col>
                <v-col class="text-right">{{ currency }} {{ subTotal }} </v-col>
            </v-row>
            <v-divider></v-divider>
        </v-row>
        <div v-for="item in calculatedTaxBreakdown" :key="item.taxId">
            <v-row class="d-flex flex-column mb-2">
                <v-row class="align-center">
                    <v-col class="text-left">
                        {{ `${item.name} ${item.rate}% (${item.type})` }}
                    </v-col>
                    <v-col class="text-right"
                        >{{ currency }} {{ item.taxCollected }}
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </v-row>
        </div>
        <v-row class="d-flex flex-column mb-2">
            <v-row class="font-weight-bold align-center text-body-1">
                <v-col class="text-left"> Total </v-col>
                <v-col class="text-right"
                    >{{ currency }} {{ calculatedTotalPrice }}
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </v-row>
    </div>
</template>

<script>
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
import { getTaxPaymentCloudFunction } from '@/util/cloudFunctions/taxes'

export default {
    props: {
        inputPrice: {
            type: Number,
            default: 0,
        },
        totalPrice: {
            type: Number,
            default: 0,
        },
        taxedItems: {
            type: Array,
            default: () => [],
        },
        taxBreakdown: {
            type: Array,
            default: () => [],
        },
        isStatic: {
            type: Boolean,
            default: false, // if true, then the tax will not be recalculated
        },
    },
    data() {
        return {
            taxData: {},
            isLoading: false,
        }
    },
    computed: {
        ...mapGetters({
            studioObject: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        currency() {
            return this.studioObject.currency ?? 'SGD'
        },
        calculatedTotalPrice() {
            return this.totalPrice || this.taxData.finalPrice || this.inputPrice
        },
        calculatedTaxBreakdown() {
            if (this.isStatic) {
                return this.taxBreakdown // if static, then get from props
            }
            return this.taxData.taxBreakdown || []
        },
        subTotal() {
            return this.taxData.subtotalPrice || this.inputPrice
        },
    },
    methods: {
        async loadTax() {
            if (this.isStatic) {
                return
            }
            try {
                this.isLoading = true
                const response = await this.getTaxPayment({
                    studioId: this.studioObject.studioId,
                    taxedItems: this.taxedItems,
                })
                if (response.data.success) {
                    this.taxData = response.data
                }
                this.isLoading = false
            } catch (error) {
                this.isLoading = false
            }
        },
        onPropsChanged: debounce(function () {
            this.loadTax()
        }, 1000),
    },
    async created() {
        this.getTaxPayment = getTaxPaymentCloudFunction
        this.loadTax()
    },
    watch: {
        taxedItems: {
            handler() {
                this.onPropsChanged()
            },
            deep: true,
        },
        inputPrice() {
            this.onPropsChanged()
        },
        isLoading(newValue) {
            this.$emit('loading-changed', newValue)
        },
    },
}
</script>
