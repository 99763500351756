<template>
    <div>
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on">
                    <v-avatar class="mr-3" size="40">
                        <v-img :src="user.profilePhoto" />
                    </v-avatar>
                    {{ user.fullName }}
                    <v-icon>mdi-menu-down</v-icon>
                </v-btn>
            </template>

            <v-list dense nav>
                <v-list-item
                    v-for="item in items"
                    :key="item.name"
                    :to="item.link"
                    :class="item.class"
                    color="primary"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    key="Change Password"
                    @click="changePassword"
                    color="primary"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-lock</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t('message.changePassword')
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item key="Logout" @click="signOut" color="primary">
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t('message.logout')
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
        <!-- Change Password Dialog -->
        <ChangePassword ref="ChangePassword" :hideButton="true" />

        <!-- Change Password Dialog -->
        <Logout ref="Logout" :hideButton="true" />
    </div>
</template>
<script>
import ChangePassword from '@/components/auth/ChangePassword'
import { mapGetters } from 'vuex'
import Logout from '@/components/auth/Logout'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
export default {
    components: {
        ChangePassword,
        Logout,
    },
    props: {
        user: Object,
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        stripeSetup() {
            return this.studio.hasSetupStripe
        },
    },
    data() {
        const studioRoute = this.$route.params.studioRoute
        const hash = this.$route.hash
        const items = [
            {
                name: this.$t('nav.myProfile'),
                icon: 'mdi-account',
                link: `/${studioRoute}/profile${hash}`,
            },
            {
                name: this.$t('nav.myClasses'),
                icon: 'mdi-book-variant',
                link: `/${studioRoute}/myclasses${hash}`,
            },
            {
                name: this.$t('message.myPackages'),
                icon: 'mdi-gift',
                link: `/${studioRoute}/mypackages${hash}`,
            },
            {
                name: this.$t('message.myOrders'),
                icon: 'mdi-clipboard-text',
                link: `/${studioRoute}/myorders${hash}`,
            },
        ]

        return {
            items,
        }
    },
    created() {
        if (this.stripeSetup) {
            const studioRoute = this.$route.params.studioRoute
            const hash = this.$route.hash
            this.items.push({
                name: this.$t('message.paymentMethods'),
                icon: 'mdi-credit-card',
                link: `/${studioRoute}/paymentmethods${hash}`,
            })
        }
    },
    methods: {
        changePassword() {
            this.$refs.ChangePassword.openDialog()
        },
        signOut() {
            this.$refs.Logout.signOut()
        },
    },
}
</script>
