import { functions, httpsCallable } from '@/firebase'

export function unsubscribe(studioId, userId, studioLeadId) {
    const unsubscribeFn = httpsCallable(
        functions,
        'unsubscribeFromMarketingEmails'
    )
    return unsubscribeFn({ studioId, userId, studioLeadId }).then(
        (result) => result.data
    )
}
