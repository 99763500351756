<template>
    <v-footer padless>
        <QRCodeDialog v-model="dialog" />
        <v-card
            class="d-flex primary flex-row justify-center"
            width="100%"
            flat
            tile
        >
            <v-card-title class="global-container">
                <div
                    @click="openVibefam"
                    style="cursor: pointer"
                    v-if="notLongBoardAcademy"
                >
                    <strong class="subheading white--text pb-3"
                        >Powered by
                        <span class="yellow--text">vibefam</span></strong
                    >
                </div>

                <v-spacer></v-spacer>

                <v-row
                    no-gutters
                    justify="center"
                    justify-sm="start"
                    justify-lg="end"
                >
                    <template v-for="socialLink in socialLinks">
                        <v-btn
                            v-if="socialLink.link"
                            :key="socialLink.link"
                            :href="socialLink.link"
                            target="_blank"
                            class="mx-4"
                            dark
                            icon
                        >
                            <v-icon size="24px">{{ socialLink.icon }}</v-icon>
                        </v-btn>
                    </template>
                </v-row>
            </v-card-title>
        </v-card>
    </v-footer>
</template>
<script>
import QRCodeDialog from '@/components/footer/QRCodeDialog'
import { mapGetters } from 'vuex'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
// Analytics
import { CLICK_POWERED_BY_VIBEFAM } from '@/analytics/events'

export default {
    components: {
        QRCodeDialog,
    },
    data() {
        return {
            dialog: false,
        }
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        notLongBoardAcademy() {
            return this.studio && this.studio.id !== '6OvuH4sFMEcIgeDYbxgz'
        },
        isMobile() {
            return this.$vuetify.breakpoint.name === 'xs'
        },
        studioLogoSize() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 52
                case 'sm':
                    return 70
                case 'md':
                case 'lg':
                case 'xl':
                    return 80
            }
            return 52
        },
        socialLinks() {
            if (this.studio && this.studio.studioSocials) {
                return [
                    {
                        icon: 'mdi-link',
                        link: this.studio.studioSocials.website || '',
                    },
                    {
                        icon: 'mdi-facebook',
                        link: this.studio.studioSocials.facebook || '',
                    },
                    {
                        icon: 'mdi-instagram',
                        link: this.studio.studioSocials.instagram || '',
                    },
                ]
            }
            return []
        },
    },
    methods: {
        logEvent(event) {
            this.$gtag.event(event)
        },
        openVibefam() {
            window.open('https://vibefam.com/', '_blank')
            this.$gtag.event(CLICK_POWERED_BY_VIBEFAM)
        },
    },
}
</script>

<style scoped>
.background {
    background-color: #a259ff;
}
.qr-code {
    height: 50px;
    cursor: pointer;
}
.letter {
    letter-spacing: 5px;
    font-weight: 600;
    font-size: 20px;
}
</style>
