import { gettersAuth } from '@/store/moduleComponents/authentication/gettersAuth'
import { actionsAuth } from '@/store/moduleComponents/authentication/actionsAuth'
import { mutationsAuth } from '@/store/moduleComponents/authentication/mutationsAuth'

export const authenticationModule = {
    namespaced: true,
    state: {
        user: null,
        error: null,
        loading: true,
        collectUserDetails: false,
        isBrandedApp: false,
        isRegistering: false,
    },
    getters: gettersAuth,
    actions: actionsAuth,
    mutations: mutationsAuth,
}
