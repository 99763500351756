<template>
    <div>
        <!-- Appbar -->
        <v-app-bar
            color="primaryMenu d-flex justify-center appbar"
            :height="appBarHeight"
        >
            <v-row class="global-container">
                <!-- Menu Icon -->
                <v-col class="flex-grow-0 px-0">
                    <v-app-bar-nav-icon
                        @click="drawer = !drawer"
                        class="d-lg-none"
                        color="primary"
                    ></v-app-bar-nav-icon>
                </v-col>

                <!-- Studio Logo -->
                <v-col class="flex-grow-0 px-0 pl-sm-3">
                    <v-avatar
                        :size="studioLogoSize"
                        class="hover"
                        @click="redirectHome"
                    >
                        <v-img :src="studio.studioLogo" v-if="studio" />
                    </v-avatar>
                </v-col>

                <!-- List of Navigation -->
                <v-col class="d-none d-lg-flex pa-0">
                    <v-container class="text-center" fill-height>
                        <v-col
                            v-for="item in computedheaderNavigation"
                            :key="item.name"
                            :class="item.class"
                        >
                            <v-btn
                                text
                                link
                                :to="item.link"
                                active-class="primary"
                            >
                                {{ item.name }}
                            </v-btn>
                        </v-col>
                    </v-container>
                </v-col>

                <!-- User Profile / Signup Login -->
                <v-col
                    class="d-none d-lg-flex user-width pa-0"
                    v-if="isUserAuth"
                >
                    <v-container class="text-center pa-0" fill-height>
                        <UserProfile :user="user" />
                    </v-container>
                </v-col>

                <v-col
                    class="d-none d-sm-flex user-width pa-0"
                    v-if="!isUserAuth"
                >
                    <v-container class="text-center pa-0" fill-height>
                        <SignUpButton />
                    </v-container>
                </v-col>

                <v-col class="d-flex user-width pa-0 px-2" v-if="!isUserAuth">
                    <v-container class="text-center pa-0" fill-height>
                        <Login />
                    </v-container>
                </v-col>
            </v-row>
        </v-app-bar>

        <!-- Navbar Drawer -->
        <v-navigation-drawer
            v-model="drawer"
            color="primaryMenu"
            class="d-lg-none"
            fixed
            temporary
        >
            <!-- Profile -->
            <v-list-item link to="profile" v-if="isUserAuth" color="primary">
                <v-list-item-avatar>
                    <v-img :src="user.profilePhoto" />
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title class="h6">{{
                        user.fullName
                    }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <!-- List of Navigation -->
            <v-list dense nav>
                <v-list-item
                    v-for="item in drawerNavigation"
                    :key="item.name"
                    :to="item.link"
                    color="primary"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    key="My Classes"
                    :to="`/${$route.params.studioRoute}/myclasses`"
                    v-if="isUserAuth"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-book-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t('nav.myClasses')
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    key="My Packages"
                    :to="`/${$route.params.studioRoute}/mypackages`"
                    v-if="isUserAuth"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-gift</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t('message.myPackages')
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    key="My Orders"
                    :to="`/${$route.params.studioRoute}/myorders`"
                    v-if="isUserAuth && !isInReview"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-clipboard-text</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t('message.myOrders')
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    key="Payment Methods"
                    :to="`/${$route.params.studioRoute}/paymentmethods`"
                    v-if="isUserAuth && stripeSetup"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-credit-card</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t('message.paymentMethods')
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    key="Change Password"
                    @click="changePassword"
                    v-if="isUserAuth"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-lock</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t('message.changePassword')
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <!-- Authentication Buttons -->
            <template v-slot:append>
                <div class="pa-2" v-if="isUserAuth">
                    <Logout block />
                </div>
                <div class="px-2" v-if="!isUserAuth">
                    <Login @openDialog="closeDrawer()" block />
                </div>
                <div class="pa-2" v-if="!isUserAuth">
                    <SignUpButton @openDialog="closeDrawer()" block />
                </div>
            </template>
        </v-navigation-drawer>

        <!-- Change Password Dialog -->
        <ChangePassword
            ref="ChangePassword"
            :hideButton="true"
            @openDialog="closeDrawer()"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SignUpButton from '@/components/auth/SignUpButton'
import Login from '@/components/auth/Login'
import Logout from '@/components/auth/Logout'
import UserProfile from '@/components/auth/UserProfile'
import ChangePassword from '@/components/auth/ChangePassword'
import {
    AUTHENTICATION_MODULE_NAME,
    STUDIO_MODULE_NAME,
} from '@/store/moduleNames'
export default {
    components: {
        SignUpButton,
        Login,
        Logout,
        UserProfile,
        ChangePassword,
    },
    computed: {
        appBarHeight() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 60
                case 'sm':
                    return 72
                case 'md':
                case 'lg':
                case 'xl':
                    return 80
            }
            return 60
        },
        studioLogoSize() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 52
                case 'sm':
                    return 60
                case 'md':
                case 'lg':
                case 'xl':
                    return 70
            }
            return 52
        },
        ...mapGetters({
            user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
            isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
            brandedAppsInReview: `${STUDIO_MODULE_NAME}/brandedAppsInReview`,
        }),
        headerNavigation() {
            const studioRoute = this.$route.params.studioRoute
            const headers = [
                {
                    link: `/${studioRoute}/classes`,
                    name: this.$t('nav.browse'),
                    class: 'item-width',
                },
                {
                    link: `/${studioRoute}/packages`,
                    name: this.$t('nav.buyPackages'),
                    class: 'item-width',
                },
            ]
            if (!this.isInReview) {
                headers.push({
                    link: `/${studioRoute}/merchandise`,
                    name: this.$t('nav.eventsAndMerch'),
                    class: 'item-width',
                })
            }
            return headers
        },
        drawerNavigation() {
            const studioRoute = this.$route.params.studioRoute
            const drawers = [
                {
                    link: `/${studioRoute}/classes`,
                    icon: 'mdi-calendar',
                    name: this.$t('nav.browse'),
                },
                {
                    link: `/${studioRoute}/packages`,
                    icon: 'mdi-wallet-membership',
                    name: this.$t('nav.buyPackages'),
                },
            ]

            if (!this.isInReview) {
                drawers.push({
                    link: `/${studioRoute}/merchandise`,
                    icon: 'mdi-cart',
                    name: this.$t('nav.eventsAndMerch'),
                })
            }
            return drawers
        },
        isInReview() {
            return (
                this.studio && this.brandedAppsInReview.includes(this.studio.id)
            )
        },
        stripeSetup() {
            return this.studio && this.studio.hasSetupStripe
        },
        computedheaderNavigation() {
            if (this.isUserAuth) {
                return this.headerNavigation.concat(this.headerNavigationAuth)
            }
            return this.headerNavigation
        },
    },
    methods: {
        changePassword() {
            this.$refs.ChangePassword.openDialog()
        },
        redirectHome() {
            const studioRoute = this.$route.params.studioRoute
            this.$router.push(`/${studioRoute}/classes`)
        },
        closeDrawer() {
            this.drawer = false
        },
    },
    data() {
        return {
            drawer: false,
            headerNavigationAuth: [],
        }
    },
}
</script>
<style scoped>
.item-width {
    width: 150px;
    flex-grow: 0;
}
@media screen and (min-width: 768px) {
    .item-width {
        width: 170px;
    }
}
.user-width {
    width: 200px;
    flex-grow: 0;
}
.hover {
    cursor: pointer;
}
</style>
