<template>
    <div>
        <v-row v-if="shouldShowCategories">
            <v-row
                v-for="(category, categoryIndex) in categoriesToDisplay"
                :key="categoryIndex"
            >
                <v-col cols="12">
                    <h3 class="font-weight-bold">{{ category.name }}</h3>
                    <p v-if="category.description">
                        {{ category.description }}
                    </p>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                    v-for="(packageItem, packageIndex) in category.packages"
                    :key="packageIndex"
                    ><PackageCard
                        :packageItem="packageItem"
                        :isBuyable="isBuyable"
                        @sharePackage="sharePackage"
                    />
                </v-col>
                <v-col cols="12">
                    <v-divider
                        class="mt-4"
                        v-if="categoryIndex < categoriesToDisplay.length - 1"
                    ></v-divider>
                </v-col>
            </v-row>
        </v-row>
        <v-row v-else>
            <v-col
                cols="12"
                md="6"
                v-for="(packageItem, index) in packages"
                :key="index"
            >
                <PackageCard
                    v-if="
                        !invalidCategories.includes(
                            packageItem.packageCategoryId
                        )
                    "
                    :packageItem="packageItem"
                    :isBuyable="isBuyable"
                    @sharePackage="sharePackage"
                />
            </v-col>
        </v-row>
    </div>
</template>
<script>
import PackageCard from '@/components/packages/PackageCard'
export default {
    props: {
        isBuyable: {
            type: Boolean,
            default: true,
        },
        type: String,
        categories: Array, // already sorted
        packages: Array,
    },
    components: {
        PackageCard,
    },
    data() {
        return {
            invalidCategories: [],
            tab: 0,
        }
    },
    computed: {
        shouldShowCategories() {
            // at least one uncategorised category with packages
            return (
                this.categoriesToDisplay.filter(
                    (category) => category.id !== 'uncategorised'
                ).length >= 1
            )
        },
        categoriesToDisplay() {
            // { ...category, packages: [packages in the category] }
            const categoriesCopy = this.categories.slice()
            for (const category of categoriesCopy) {
                category.packages = this.packages.filter(
                    (packageItem) =>
                        packageItem.packageCategoryId === category.id
                )
            }

            const uncategorisedPackages = this.packages.filter(
                (pkg) =>
                    !pkg.packageCategoryId ||
                    // invalid packageCategoryId
                    !categoriesCopy.some(
                        (category) => category.id === pkg.packageCategoryId
                    )
            )

            const packageType =
                this.type === 'one-time' ? 'OneTime' : 'Recurring'

            categoriesCopy.forEach((category) => {
                if (category.packageType != packageType) {
                    this.invalidCategories.push(category.id)
                }
            })

            categoriesCopy.push({
                id: 'uncategorised',
                name: this.$t('message.others'),
                description: '',
                packageType,
                packages: uncategorisedPackages,
            })

            return categoriesCopy.filter(
                (category) =>
                    category.packages.length > 0 &&
                    category.packageType === packageType
            )
        },
    },
    methods: {
        sharePackage() {
            this.$emit('sharePackage')
        },
    },
}
</script>
