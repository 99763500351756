<template>
    <div>
        <v-btn
            @click="getSubscriptionCancellationStatus"
            color="warning"
            elevation="0"
        >
            <v-icon class="pr-1">mdi-cancel</v-icon>
            {{ $t('message.cancelPackage') }}
        </v-btn>

        <div v-if="dialog">
            <WarningDialog
                v-if="showWarningDialog"
                :showDialog="showWarningDialog"
                :title="$t('message.cancelPackageTitle')"
                :content="$t('message.cancelPackageMessage')"
                :confirmButtonText="$t('message.confirm')"
                :confirmCallback="cancelPackage"
                :cancelCallback="closeDialog"
                :submitting="cancelLoading"
                :errorMessage="error"
            />
            <ChargePackageCancellationPaymentMethodDialog
                v-else-if="showChargePackageCancellationPaymentMethodDialog"
                :dialog="showChargePackageCancellationPaymentMethodDialog"
                :subscriptionCancellationFee="subscriptionCancellationFee"
                :taxes="taxes"
                @closeDialog="closeDialog"
                @chargeStatus="emitStatus"
            />

            <v-dialog v-model="dialog" v-else max-width="500">
                <v-card> <v-skeleton-loader type="card" /> </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import ChargePackageCancellationPaymentMethodDialog from '../paymentmethods/ChargePackageCancellationPaymentMethodDialog.vue'
import WarningDialog from '../shared/WarningDialog.vue'
import {
    getSubscriptionCancellationStatus,
    cancelRecurringPackage,
} from '../../util/cloudFunctions/packagesFunctions'

export default {
    components: {
        WarningDialog,
        ChargePackageCancellationPaymentMethodDialog,
    },
    props: {
        packageId: {
            type: String,
            required: true,
        },
    },
    provide() {
        return {
            packageId: this.packageId,
        }
    },
    computed: {
        showWarningDialog() {
            return !this.loading && !this.isChargable && this.dialog
        },
        showChargePackageCancellationPaymentMethodDialog() {
            return !this.loading && this.isChargable && this.dialog
        },
    },
    data() {
        return {
            loading: false,
            isChargable: false,
            subscriptionCancellationFee: null,
            taxes: {},
            dialog: false,
            cancelLoading: false,
            error: null,
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false
        },
        async getSubscriptionCancellationStatus() {
            this.loading = true
            this.dialog = true

            const result = await getSubscriptionCancellationStatus(
                this.packageId
            )
            if (result.success) {
                this.isChargable =
                    result.isEarlyCancellation &&
                    result.isSubscriptionCancellationChargable

                this.subscriptionCancellationFee = this.isChargable
                    ? result.subscriptionCancellationFee
                    : 0

                if (result.taxes) {
                    this.taxes = result.taxes
                }
            }
            this.loading = false
        },
        async cancelPackage() {
            this.cancelLoading = true

            const result = await cancelRecurringPackage(this.packageId)

            if (result.success) {
                this.emitStatus(true, null)
                this.closeDialog()
            } else {
                this.error = result.error
                this.emitStatus(false, this.error)
            }

            this.cancelLoading = false
        },
        emitStatus(isSuccess, error) {
            this.$emit('cancelStatus', isSuccess, error)
        },
    },
}
</script>
