import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import StudioRouteWrapper from '@/views/StudioRouteWrapper'
import ClassesAndCourses from '@/views/ClassesAndCourses'
import Packages from '@/views/Packages'
import Merchandise from '@/views/Merchandise'
import ShoppingCart from '@/views/ShoppingCart'
import MyClasses from '@/views/MyClasses'
import MyPackages from '@/views/MyPackages'
import BookClass from '@/views/BookClass'
import BookCourse from '@/views/BookCourse'
import Profile from '@/views/Profile'
import ErrorPage from '@/views/404'
import PaymentSuccess from '@/views/PaymentSuccess'
import PaymentCanceled from '@/views/PaymentCanceled'
import Unsubscribe from '@/views/Unsubscribe'
import MyOrders from '@/views/MyOrders'
import OrderDetails from '@/views/OrderDetails'
import ManageClass from '@/views/ManageClass'
import SignUp from '@/views/SignUp'
import PaymentMethods from '@/views/PaymentMethods'
import SetupSuccess from '@/views/SetupSuccess'
import SetupCanceled from '@/views/SetupCanceled'
import InterestForm from '@/views/InterestForm'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Home,
    },
    {
        path: '/success',
        component: PaymentSuccess,
    },
    {
        path: '/canceled',
        component: PaymentCanceled,
    },
    {
        path: '/setupSuccess',
        component: SetupSuccess,
    },
    {
        path: '/setupCanceled',
        component: SetupCanceled,
    },
    {
        path: '/404',
        component: ErrorPage,
    },
    {
        path: '/unsubscribe',
        component: Unsubscribe,
        props: (route) => ({
            userId: route.query.userId === 'null' ? null : route.query.userId,
            studioId: route.query.studioId,
            studioLeadId: route.query.studioLeadId,
        }),
    },
    {
        path: '/:studioRoute',
        component: StudioRouteWrapper,
        redirect: '/:studioRoute/classes',
        children: [
            {
                path: 'classes',
                component: ClassesAndCourses,
            },
            {
                path: 'classes/:classId',
                component: BookClass,
            },
            {
                path: 'courses/:courseId',
                component: BookCourse,
            },
            {
                path: 'packages',
                component: Packages,
            },
            {
                path: 'merchandise',
                component: Merchandise,
            },
            {
                path: 'merchandise/cart',
                component: ShoppingCart,
            },
            {
                path: 'myclasses',
                component: MyClasses,
            },
            {
                path: 'myclasses/:classId',
                component: ManageClass,
            },
            {
                path: 'mypackages',
                component: MyPackages,
            },
            {
                path: 'myorders',
                component: MyOrders,
            },
            {
                path: 'myorders/:orderId',
                component: OrderDetails,
            },
            {
                path: 'profile',
                component: Profile,
            },
            {
                path: 'signup',
                component: SignUp,
            },
            {
                path: 'paymentmethods',
                component: PaymentMethods,
            },
            {
                path: 'interest',
                component: InterestForm,
            },
        ],
    },
    {
        path: '*',
        redirect: '/404',
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_BASE_URL,
    routes,
})

export default router
