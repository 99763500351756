<template>
    <v-card height="100%" class="d-flex flex-column">
        <v-card-text class="ordinary--text">
            <v-row class="justify-space-between">
                <!-- Left Column of Package Item -->
                <v-col cols="7" sm="8">
                    <!-- Package Title -->
                    <v-row no-gutters>
                        <p class="primary--text" :style="packageItemNameStyle">
                            {{ packageItem.name }}
                        </p>
                    </v-row>

                    <p>
                        {{ packageDescription }}
                    </p>

                    <p
                        ref="expiry"
                        class="text--secondary"
                        v-if="!isBuyable && packageItem.hasStarted"
                    >
                        {{ expiresOn }}<br />
                        {{ purchasedOn }}<br />
                        {{ packageStatus }}
                    </p>
                    <p
                        ref="newPurchaseExpiry"
                        class="text--secondary"
                        v-if="!isBuyable && !packageItem.hasStarted"
                    >
                        {{ newPurchaseExpiry[0] }}
                        <br />
                        {{ newPurchaseExpiry[1] }}
                    </p>

                    <p
                        class="primary--text mb-1 d-none d-sm-block text-truncate"
                        v-if="isBuyable"
                    >
                        {{ $t('message.classesEligible') }}
                    </p>
                    <p
                        class="d-none d-sm-block classes-restrictions"
                        v-if="isBuyable"
                    >
                        {{ classesRestrictions }}
                    </p>
                </v-col>

                <!-- Right Column of Package Item -->
                <v-row
                    cols="5"
                    sm="4"
                    class="d-flex flex-column justify-space-between pa-4"
                >
                    <v-row class="justify-end">
                        <v-col>
                            <h3 class="text-center mt-3">
                                {{ `${studio.currency}${packageItem.price}` }}
                            </h3>
                        </v-col>
                    </v-row>
                    <CheckoutWindow
                        :packageItem="packageItem"
                        :buttonText="this.$t('message.buyNow')"
                        v-if="isBuyable"
                        :isPurchasePackage="true"
                    />

                    <!-- Button to change payment method -->
                    <v-btn
                        v-if="showChangePaymentMethodBtn"
                        @click="showPaymentMethodsDialog = true"
                        outlined
                        class="ma-2"
                    >
                        <v-icon class="pr-1">mdi-credit-card</v-icon>
                        {{ $t('message.changeCard') }}
                    </v-btn>

                    <!-- Button to cancel recurring package -->
                    <PackageCancelButton
                        v-if="isRecurringPackageUserCancellable"
                        class="d-flex flex-column ma-2"
                        :packageId="packageItem.id"
                        @cancelStatus="cancelStatus"
                    />

                    <!-- PaymentMethods Dialog -->
                    <v-dialog
                        v-model="showPaymentMethodsDialog"
                        max-width="600px"
                        @click:outside="showPaymentMethodsDialog = false"
                        @keydown:esc="showPaymentMethodsDialog = false"
                    >
                        <v-card class="d-flex flex-column">
                            <v-icon
                                @click="showPaymentMethodsDialog = false"
                                class="align-self-end mr-4 mt-4"
                                >mdi-close</v-icon
                            >

                            <PaymentMethods :packageId="packageItem.id" />
                        </v-card>
                    </v-dialog>
                </v-row>
            </v-row>
        </v-card-text>

        <v-spacer />

        <!-- View More Button -->
        <PackageInfo
            class="d-flex"
            block
            classProp="primary--text"
            :buttonText="this.$t('message.viewMore')"
            :packageItem="packageItem"
            :isBuyable="isBuyable"
            @sharePackage="sharePackage"
        />
    </v-card>
</template>
<script>
import PackageInfo from '@/components/packages/PackageInfo'
import CheckoutWindow from '@/components/checkout/CheckoutWindow'
import { dateStringFromTimestamp } from '@/util/dateformat'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import { mapGetters } from 'vuex'
import { WIDGET_ROUTE } from '@/constants'
import PaymentMethods from '@/views/PaymentMethods'
import PackageCancelButton from './PackageCancelButton.vue'

export default {
    components: {
        PackageInfo,
        CheckoutWindow,
        PaymentMethods,
        PackageCancelButton,
    },
    data() {
        return {
            showPaymentMethodsDialog: false,
        }
    },
    props: {
        packageItem: Object,
        isBuyable: Boolean,
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        renewDurationType() {
            const packageItem = this.packageItem
            const recurring = packageItem.renewDurationType
            if (!recurring) {
                return ''
            }
            const renewalDurationTypeLen = recurring.length
            const lastRenewalDurationLetter =
                recurring[renewalDurationTypeLen - 1]
            if (lastRenewalDurationLetter === 's') {
                return recurring.substring(0, renewalDurationTypeLen - 1)
            } else {
                return recurring
            }
        },
        packageDescription() {
            const packageItem = this.packageItem
            const recurring = packageItem.renewDuration
            if (this.isBuyable) {
                const criteriaMessage =
                    packageItem.expiryImmediateCriterion === 'expiry-immediate'
                        ? this.$t('message.purchase')
                        : this.$t('message.firstClass')
                if (recurring) {
                    const numCycles = packageItem.numCycles
                    const emptyValuesToCheck = [undefined, null, '', '0']
                    //using array because of "0" not being a falsy value
                    const hasCycles = !emptyValuesToCheck.includes(
                        packageItem.numCycles
                    )
                    const renewalDuration = `${packageItem.renewDuration} ${this.renewDurationType}`
                    const stringValue = hasCycles
                        ? this.$t('message.packageRenewWithCycles', {
                              renewalDuration: renewalDuration,
                              expiresAfter:
                                  packageItem.renewDuration * numCycles, // e.g expires after {n} days
                              renewDurationType: this.renewDurationType,
                          })
                        : this.$t('message.packageRenewWithoutCycles', {
                              renewalDuration: renewalDuration,
                          })
                    return `${packageItem.classNumbers} ${this.$t(
                        'message.credits'
                    )} - ${stringValue}`
                } else {
                    return `${packageItem.classNumbers} ${this.$t(
                        'message.credits'
                    )} - ${this.$t('message.packageValidFor', {
                        duration:
                            packageItem.durationOriginal ??
                            packageItem.duration,
                        durationType: packageItem.durationType,
                        criteriaMessage: criteriaMessage,
                    })}`
                }
            }
            return `${packageItem.classesLeft} / ${
                packageItem.numberClasses
            } ${this.$t('message.classesLeft')}`
        },
        expiresOn() {
            const packageItem = this.packageItem
            if (this.isBuyable) {
                return ''
            }
            return `${this.$t('message.expiresOn')} ${dateStringFromTimestamp(
                packageItem.expiresOn,
                this.studio.timezone
            )}`
        },
        purchasedOn() {
            const packageItem = this.packageItem
            if (this.isBuyable) {
                return ''
            }
            return `${this.$t('message.purchasedOn')} ${dateStringFromTimestamp(
                packageItem.datePurchased,
                this.studio.timezone
            )}`
        },
        packageStatus() {
            return !this.isRecurringPackage
                ? ''
                : this.packageItem.status === 'active'
                ? ''
                : this.$t('message.recurringPackageInactive')
        },
        newPurchaseExpiry() {
            const packageItem = this.packageItem

            if (this.isBuyable) {
                return ''
            }

            return [
                `${this.$t(
                    'message.bookFirstClassBy'
                )} ${dateStringFromTimestamp(
                    packageItem.expiresOn,
                    this.studio.timezone
                )}.`,
                this.$t('message.packageValidFrom', {
                    duration:
                        packageItem.durationOriginal ?? packageItem.duration,
                    durationType: packageItem.durationType,
                }),
            ]
        },
        classesRestrictions() {
            const packageItem = this.packageItem
            if (packageItem.hasClassRestriction) {
                return packageItem.classes.join(', ')
            }
            return this.$t('message.classesAll')
        },
        isWidget() {
            return this.$route.hash === WIDGET_ROUTE
        },
        packageItemNameStyle() {
            let color = '#a259ff'
            let backgroundColor = '#a259ff2e'
            if (this.studio.primaryColor) {
                color = this.studio.primaryColor
                backgroundColor = color + '2e'
            }
            return {
                padding: '8px 16px !important',
                marginBottom: 30,
                borderRadius: '4px',
                backgroundColor: backgroundColor,
                color: color,
                fontWeight: 500,
            }
        },
        isRecurringPackage() {
            return this.packageItem.isSubscription
        },
        isRecurringPackageUserCancellable() {
            return (
                this.isRecurringPackage &&
                !this.isBuyable &&
                this.packageItem.isUserCancellable &&
                this.packageItem.status === 'active'
            )
        },
        showChangePaymentMethodBtn() {
            return (
                this.isRecurringPackage &&
                !this.isBuyable &&
                this.packageItem.status === 'active'
            )
        },
    },
    methods: {
        sharePackage() {
            this.$emit('sharePackage')
        },
        cancelStatus(isSuccess, error) {
            this.$emit('cancelStatus', isSuccess, error)
        },
    },
}
</script>
<style scoped>
.right-col {
    max-width: 160px !important;
}
</style>
